<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <ContactFormHeader v-bind="{ title }" />
        <VehicleSummaryBox
          v-if="product !== 'General'"
          v-bind="{ description: data.description, vin: data.vin }"
        />
        <div v-if="!submitted" class="content">
          <p>{{ subtitle }}</p>
          <ExperianDetails v-if="isExperian" v-bind="{ product }" />
          <ContactForm v-bind="{ product, isUser }" @submit="submit" />
        </div>
        <ContactFormSubmitted v-else />
      </div>
    </div>
  </div>
</template>

<script>
import { guest } from '@/plugins/axios'
import { mapGetters } from 'vuex'
export default {
  name: 'Contact',
  components: {
    ContactFormHeader: () => import('../components/ContactFormHeader'),
    VehicleSummaryBox: () =>
      import('modules/shared/vehicle-summary-box/VehicleSummaryBox'),
    ContactForm: () => import('../components/ContactForm'),
    ExperianDetails: () =>
      import('../components/experian/ContactFormFullDetails'),
    ContactFormSubmitted: () => import('../components/ContactFormSubmitted')
  },
  data: () => ({ submitted: false }),
  computed: {
    ...mapGetters('auth', ['isUser', 'user', 'branch', 'organisation']),
    ...mapGetters({ data: 'modal/config' }),
    isExperian() {
      return this.$experian
    },
    title() {
      return this.data.vrm
        ? `${this.product} enquiry for ${this.data.vrm}`
        : 'Get support'
    },
    subtitle() {
      return this.data.vrm
        ? 'Please complete this form if you would like our dedicated support team to investigate a query on this vehicle. We will get back to you by email within 24 hours.'
        : 'If you have any questions or need help, please fill out the form below and our dedicated support team will get back to you by email within 24 hours.'
    },
    product() {
      return this.data.product
    },
    brand() {
      return this.isExperian ? 'experian' : 'cazana'
    }
  },
  methods: {
    track(formData) {
      this.$mxp.track('send_contact_form', {
        comments: formData.enquiry.comments,
        categories: formData.enquiry.categories,
        userId: this.user.id || 0,
        branch: this.branch,
        product: this.product,
        vrm: this.data.vrm,
        vin: this.data.vin,
        description: this.data.description,
        contactByPhone: formData.contactByPhone,
        brand: this.brand
      })
    },
    async submit(formData) {
      const { enquiry, contactDetails } = formData
      const { isUser, user, branch, organisation, brand, product } = this
      const { vrm, vin, description } = this.data

      try {
        this.track(formData)
        this.$wait.start('contact-form')

        const body = {
          enquiry,
          user: isUser ? user : contactDetails,
          userId: user.id || 0,
          branch: branch || {},
          organisation: organisation || {},
          product,
          vrm,
          vin,
          description,
          brand
        }
        await guest.post('/misc/contact-us', body)
        this.submitted = true
      } catch {
        this.$mxp.track('send_contact_form_failed')
        this.$notify({
          group: 'danger',
          text: 'Something went wrong, please try again'
        })
      } finally {
        this.$wait.end('contact-form')
      }
    }
  }
}
</script>
